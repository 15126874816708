import React from "react";
import { useParams } from "react-router-dom";
import blogData from "../../data/blog/blogs.json";
import { Helmet } from "react-helmet-async";
import RenderProfile from "../../Components/Common/RenderProfile";
import RecentsBlogs from "../../Components/Common/RecentsBlogs";
import One from "./BlogPages/One";
import Two from "./BlogPages/Two";
import Three from "./BlogPages/Three";
import Four from "./BlogPages/Four";
import Five from "./BlogPages/Five";
import Six from "./BlogPages/Six";
import Seven from "./BlogPages/Seven";
import Eight from "./BlogPages/Eight";
import Nine from "./BlogPages/Nine";
import Ten from "./BlogPages/Ten";
import Eleven from "./BlogPages/Eleven";
import Twelve from "./BlogPages/Twelve";
import Thirteen from "./BlogPages/Thirteen";
import Forteen from "./BlogPages/Forteen";
import Fiftheen from "./BlogPages/Fiftheen";
import Sixtheen from "./BlogPages/Sixtheen";
import Seventeenth from "./BlogPages/Seventeenth";
import Eighteen from "./BlogPages/Eighteen";
import Nineteen from "./BlogPages/Nineteen";
import Twenty from "./BlogPages/Twenty";
import TwentyOne from "./BlogPages/TwentyOne";
import TwentyTwo from "./BlogPages/TwentyTwo";
import TwentyThree from "./BlogPages/TwentyThree";

const BlogMap = {
  1: One,
  2: Two,
  3: Three,
  4: Four,
  5: Five,
  6: Six,
  7: Seven,
  8: Eight,
  9: Nine,
  10: Ten,
  11: Eleven,
  12: Twelve,
  13: Thirteen,
  14: Forteen,
  15: Fiftheen,
  16: Sixtheen,
  17: Seventeenth,
  18: Eighteen,
  19: Nineteen,
  20: Twenty,
  21: TwentyOne,
  22: TwentyTwo,
  23: TwentyThree,
};

const ReturnBlogById = ({ id }) => {
  const Component = BlogMap[id];
  return <>{Component && <Component />}</>;
};

const BlogLayout = () => {
  const params = useParams();
  const ReturnCurrentBlogByTitle = (title) => {
    const foundBlog = blogData.blogs.find((blog) => blog.title === title);
    return foundBlog || null;
  };
  const data = ReturnCurrentBlogByTitle(params.title);
  return (
    <>
      {data && (
        <>
          <Helmet>
            <title>Blogs | {data.seoTitle} | Insights & Industry Trends</title>
            <meta
              name="description"
              content="Stay informed with the latest insights, industry trends, and expert advice from StarOps Technologies. Explore our blogs for valuable content on IT, web, app development, and DevOps."
              key="description"
            />
            <meta name="keywords" content={data.seoKeywords} />
          </Helmet>
          <div className="h-5"></div>
          <div className="p-2 md:p-6 container mx-auto w-full  flex flex-col gap-3 items-start justify-center rounded-lg ">
            <h1 className="text-5xl font-medium border-b-2  w-full py-1 leading-snug">
              {data.heading}
            </h1>
            <div className="w-full flex flex-row justify-between">
              <RenderProfile name={data.author} />
              <p className="text-gray-600 text-sm font-semibold">{data.date}</p>
            </div>

            <div className="h-5 border-t-2 border-b-2 "></div>
            <div className="text-lg sm:text-xl w-full p-2 sm:leading-8 text-gray-700 ">
              <ReturnBlogById id={data.id} />
            </div>
          </div>
          <RecentsBlogs heading="Related Blogs" recents={data.related} />
        </>
      )}
    </>
  );
};

export default BlogLayout;
